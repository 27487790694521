
import { computed, defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { formatDateTime } from "@/core/directive/function/common";
import { getDraftOrderRouteUrl } from "@/core/directive/function/doubleSaleOrder";
import WindowResize from "@/components/layout/WindowResize.vue";
import { setModuleBCN } from "@/core/directive/function/common";

export default defineComponent({
  name: "double-sale-order-shipment-label-header-info",
  components: { WindowResize },
  setup() {
    const { t } = useI18n();

    const route = useRoute();
    const router = useRouter();

    const childMainRef = ref();
    const loading = ref(true);

    const options = ref({
      distribution_model: new Map([]),
      payment_status: new Map([
        [1, t("salesOrder.paymentStatusPending")],
        [2, t("salesOrder.paymentStatusPaid")],
      ]),
    });

    const isOverview = computed(() => {
      let flag = false;
      switch (route.name) {
        case "double-sale-order-shipment-label-overview":
          flag = true;
          break;
      }
      return flag;
    });

    const formInfo = ref({
      warehouse_name: "",
      status: 0,
      logistics_available: 0,
      logistics_total: 0,
    });

    const getFormInfo = (val) => {
      loading.value = true;
      formInfo.value.warehouse_name = val.warehouse_name;
      formInfo.value.status = val.status;
      formInfo.value.logistics_available = val.logistics_available;
      formInfo.value.logistics_total = val.logistics_total;
      loading.value = false;
    };

    onMounted(() => {
      setModuleBCN(t, route, router);
    });

    return {
      t,
      formatDateTime,
      getDraftOrderRouteUrl,
      childMainRef,
      loading,
      options,
      isOverview,
      getFormInfo,
      formInfo,
    };
  },
});
