import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createTextVNode as _createTextVNode, createBlock as _createBlock, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = { class: "card mb-5 mb-xl-10" }
const _hoisted_2 = { class: "card-body pb-0" }
const _hoisted_3 = { class: "d-flex flex-column flex-wrap flex-sm-nowrap break-all p-0" }
const _hoisted_4 = { class: "flex-grow-1" }
const _hoisted_5 = { class: "mb-8" }
const _hoisted_6 = { class: "d-inline fw-bold me-2 align-middle" }
const _hoisted_7 = {
  key: 0,
  class: "d-inline align-middle"
}
const _hoisted_8 = { class: "svg-icon svg-icon-3 svg-icon-success" }
const _hoisted_9 = {
  key: 1,
  class: "d-inline align-middle"
}
const _hoisted_10 = { class: "svg-icon svg-icon-3 svg-icon-danger" }
const _hoisted_11 = { class: "mb-4" }
const _hoisted_12 = { class: "d-flex align-items-center" }
const _hoisted_13 = { class: "fs-4 fw-bold" }
const _hoisted_14 = { class: "fw-semibold fs-6 text-gray-400" }
const _hoisted_15 = { class: "d-flex align-items-center" }
const _hoisted_16 = { class: "fs-4 fw-bold" }
const _hoisted_17 = { class: "fw-semibold fs-6 text-gray-400" }
const _hoisted_18 = { class: "d-flex flex-wrap flex-start" }
const _hoisted_19 = { class: "border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-4 mb-3" }
const _hoisted_20 = { class: "d-flex align-items-center" }
const _hoisted_21 = { class: "fs-4 fw-bold" }
const _hoisted_22 = { class: "fw-semibold fs-6 text-gray-400" }
const _hoisted_23 = { class: "border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3" }
const _hoisted_24 = { class: "d-flex align-items-center" }
const _hoisted_25 = { class: "fs-4 fw-bold" }
const _hoisted_26 = { class: "fw-semibold fs-6 text-gray-400" }
const _hoisted_27 = { class: "nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder" }
const _hoisted_28 = { class: "nav-item mt-2" }
const _hoisted_29 = { class: "nav-item mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg_icon = _resolveComponent("inline-svg-icon")!
  const _component_window_resize = _resolveComponent("window-resize")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("h1", _hoisted_6, _toDisplayString(_ctx.formInfo.warehouse_name), 1),
              (_ctx.formInfo.status == 10)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createElementVNode("span", _hoisted_8, [
                      _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen043.svg" })
                    ])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createElementVNode("span", _hoisted_10, [
                      _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen044.svg" })
                    ])
                  ]))
            ]),
            _createVNode(_component_window_resize, null, {
              sm: _withCtx(() => [
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.formInfo.logistics_total), 1)
                  ]),
                  _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.t("shipmentLabel.shipmentLabelTotal")), 1)
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.formInfo.logistics_available), 1)
                  ]),
                  _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.t("shipmentLabel.shipmentLabelAvailable")), 1)
                ])
              ]),
              lg: _withCtx(() => [
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("div", _hoisted_19, [
                    _createElementVNode("div", _hoisted_20, [
                      _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.formInfo.logistics_total), 1)
                    ]),
                    _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.t("shipmentLabel.shipmentLabelTotal")), 1)
                  ]),
                  _createElementVNode("div", _hoisted_23, [
                    _createElementVNode("div", _hoisted_24, [
                      _createElementVNode("div", _hoisted_25, _toDisplayString(_ctx.formInfo.logistics_available), 1)
                    ]),
                    _createElementVNode("div", _hoisted_26, _toDisplayString(_ctx.t("shipmentLabel.shipmentLabelAvailable")), 1)
                  ])
                ])
              ]),
              _: 1
            })
          ])
        ])), [
          [_directive_loading, _ctx.loading]
        ]),
        _createElementVNode("ul", _hoisted_27, [
          _createElementVNode("li", _hoisted_28, [
            _createVNode(_component_router_link, {
              to: 
                '/double-sale-order/shipment-label/' +
                _ctx.$route.params.id +
                '/list'
              ,
              replace: "",
              class: "nav-link text-active-primary ms-0 me-10 py-5",
              "active-class": "active"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t("shipmentLabel.shipmentLabelList")), 1)
              ]),
              _: 1
            }, 8, ["to"])
          ]),
          _createElementVNode("li", _hoisted_29, [
            (_ctx.isOverview)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 0,
                  to: 
                '/double-sale-order/shipment-label/' +
                _ctx.$route.params.id +
                '/detail'
              ,
                  class: "nav-link text-active-primary ms-0 me-10 py-5"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("shipmentLabel.shipmentLabelDetail")), 1)
                  ]),
                  _: 1
                }, 8, ["to"]))
              : (_openBlock(), _createBlock(_component_router_link, {
                  key: 1,
                  to: 
                '/double-sale-order/shipment-label/' +
                _ctx.$route.params.id +
                '/detail'
              ,
                  replace: "",
                  class: "nav-link text-active-primary ms-0 me-10 py-5",
                  "active-class": "active"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("shipmentLabel.shipmentLabelDetail")), 1)
                  ]),
                  _: 1
                }, 8, ["to"]))
          ])
        ])
      ])
    ]),
    _createVNode(_component_router_view, {
      onGetFormData: _ctx.getFormInfo,
      loading: _ctx.loading
    }, {
      default: _withCtx(({ Component }) => [
        (_openBlock(), _createBlock(_resolveDynamicComponent(Component), { ref: "childMainRef" }, null, 512))
      ]),
      _: 1
    }, 8, ["onGetFormData", "loading"])
  ]))
}